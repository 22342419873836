import * as React from 'react';
import {
    useListContext
} from 'react-admin';
import {
    Box,
    CircularProgress,
} from '@mui/material';

const LoadingOverlay = ({ children }) => {
    const { isFetching } = useListContext();
    return (
        <Box position="relative">
            {children}
            {isFetching && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255, 255, 255, 0.7)"
                    zIndex={10}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};
export default LoadingOverlay;
